<template>
  <AuthComponent
    :items="items"
    :type="'login'"
    :item-tab="tab"
    @perform-main-action="submitForm"
    @goBackToLogin="goBackTab"
  >
    <template #forgot-password>
      <v-col
        v-show="tab == enumOptions.mainView"
        class="lg-8"
      >
        <span
          class="text text-l-large-regular-link primary--text clickable mb-4"
          @click="forgotPassword"
        >Forgot password</span>
      </v-col>
    </template>
  </AuthComponent>
</template>

<script>
import AuthComponent from "@/components/auth/AuthComponent";
import ValidatorComponent from '@/components/common/ValidatorComponent';
import { mapActions, mapGetters } from "vuex";
export default {
  components: { AuthComponent },
  props: ['comingFromRoute'],
  data: () => ({
    tab: 0,
    rules: ValidatorComponent.data().rules,
    email: null,
    password: null,
    enumOptions: { mainView: 0, forgotPassword: 1 },
    items: [
      {
        tab: 0,
        title: "Welcome back",
        content: "New to acapedia?",
        slot: "email-password",
        btnPrimaryText: "Log in",
        btnOptionalText: "Register",
      },
      {
        tab: 1,
        title: "Forgot password",
        content:
          "We will send you an email to reset your password",
        slot: "password-reset",
        btnPrimaryText: "Confirm",
        btnOptionalText: ''
      },
    ],
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
  },
  async mounted() {
    this.$nextTick();
    if ( this.currentUser ) {
      this.$router.push({ name: "home" });
    }
  },
  methods: {
        ...mapActions('auth', ['login', 'getUser']),
    submitForm(form, isGoogleLogin) {

      if(!isGoogleLogin) {

        const isValidEmailField = ValidatorComponent.data().rules.email(form.email) === true;
        const isValidPasswordField = ValidatorComponent.data().rules.password(form.password) === true;

        if (!isValidEmailField  || (!isValidPasswordField  && !isSocialRegister)) {
          return;
        }

        this.login(form).then( () => {
          this.getUser();
          this.routePostAuthAction();

        }).catch( error => {
          this.$toasted.clear();
          this.$toasted.global.showError({
            message: error.response.data.errors.email[0],
          });

        });
      } else {
        this.routePostAuthAction();
      }
    },
    routePostAuthAction() {
      const params = this.$route.params.data;

      if ( params?.nextRoute === "home" && params?.redirect === "vote" ) {
        this.$router.push({ name: params.nextRoute, params: { data: params } });
      } else if(params?.nextRoute === "article" && params.redirect === "vote") {
        const articleId = params.articleId;
        this.$router.push({ name: params.nextRoute, params: { id: articleId, data: params } });
      } else if ( params?.redirect === "subscribe-to-keyword" ) {

        //TODO: subcription request here

        this.$router.push({ name: params.nextRoute, params: { keyword:  params?.keyword } });

      } else if( params?.nextRoute  ){
         this.$router.push({ name: params.nextRoute, params: { id:  params?.articleId } });
      } else {
        this.$router.push({ name: "home" });
      }
    },
    forgotPassword() {
      this.tab = this.enumOptions.forgotPassword;
    },
    goBackTab(data) {
      this.tab = data;
    }
  },
}
</script>
